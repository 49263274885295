import firebase from "../firebase";

const db = firebase.ref("/User");

class TutorialDataService {
  getAllChats() {
    return db;
  }

  sendChat(tutorial) {
    return db.push(tutorial);
  }

}

export default new TutorialDataService();