<template>
	<div class="playlist">
		<loader v-if="loading"></loader>
		<section class="playlist top-spacing bg-grey">
			<div class="same-space pt-4 mt-4 pb-5 ">
				<div class="container">
					<div class="main-heading pb-3">
						<div class="pattern">
							<img src="assets/images/Design-Square-Ellipse.png">
						</div>
						<h1 class="title-section pt-0 pb-2">
							My Playlist
						</h1>
					</div>
					
					<div class="row" v-if="playlist.length > 0">					
							<div class="gride-container">
								<div class="gride-box" v-for="value in playlist" :key="value.playlist_id">
									<router-link :to="{name: 'PlaylistSongs', params:{playlistID: value.playlist_id}}">
										<div class="loader-static">
											<div class="loader-dynamic">
												<img src="assets/images/placeholder.png">
											</div>
											<img src="assets/images/Square.png" class="stactic-img">
										</div>
										<div class="gride-content">
											<h6>{{ value.name }}</h6>
										
										</div>
									</router-link>
								</div>
							</div>
					</div>
					<div class="nodata faicon_style" v-else>
						<router-link to="/allcategory"><h1><i class="fa fa-play-circle-o"></i><br>No Records Found</h1></router-link>
					</div>
					<br>
					<div class="number-of" v-if="last_page > 1">
						<button class="btn btn-primary btn-sm" :disabled="current_page === 1" @click="prevCategory">Prev</button>
						{{ current_page }} / {{ last_page }}
						<button class="btn btn-primary btn-sm" :disabled="current_page === pages" @click="nextCategory">Next</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import {HTTP} from '../../_helper/http-constants.js';
	export default {
		name: "Playlist",
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				playlist: null,
				current_page: 1,
				pages: null,
				limit: 50,
				loading: false
			}
		},
		methods: {
			nextCategory() {
				this.current_page = (this.pages > this.current_page) ? parseInt(this.current_page) + 1 : this.current_page;
				this.getPlaylistData();
			},
			prevCategory() {
				this.current_page = (this.current_page > 1) ? parseInt(this.current_page) - 1 : this.current_page;
				this.getPlaylistData();
			},
			getPlaylistData() {
				this.loading = true;
				HTTP.post(`/playlist?limit=${this.limit}&page=${this.current_page}`,{
					user_id: this.user.user_id
				}).then(res => {
					if(res.data.status === 200){
						this.playlist = res.data.data;
						this.current_page = res.data.current_page;
						this.pages = res.data.last_page;
						this.loading = false;
					}else{
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 3000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err)
				});
			},
			RemovePlaylist(playlist_id = '') {
				this.loading = true;
				HTTP.post('/playlist/remove', {
					user_id: this.user.user_id,
					playlist_id: playlist_id
				}).then(res => {
					if(res.data.status === 200){
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 3000,
							timerProgressBar: true,
							icon: 'success',
							title: res.data.message
						});
						this.getPlaylistData();
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 3000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err)
				});
			}
		},
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getPlaylistData();
		}
	}
</script>