<template>
	<div class="liveradio top-spacing">
		<loader v-if="loading"></loader>
		<section class="live-radio-wrapper">
			
				<div class="radion-div">
					<div class="container">
							<div class="img-radio">
								<img :src="liveradio[0].song_image">
							
							<div class="push-play">
								<a href="javascript:void();" @click="playRadio(liveradio[0], liveradio)">
									<img src="assets/images/play-icon.png">
								</a>
							</div>
							<div class="radio-title">{{ liveradio[0].song_name }}</div>
						</div>
					</div>        
				</div>
		</section>
	</div>
</template>
<script>
	import {HTTP} from '../../_helper/http-constants.js';
	import { EventEmmiter } from "../../eventEmmiter.js";
	export default {
		name: "LiveRadio",
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				liveradio: null,
				loading: false
			}
		},
		methods: {
			getLiveRadio() {
				this.loading = true;
				HTTP.get('/liveradio').then(res => {
					if(res.data.status === 200){
						this.liveradio = res.data.data;
						this.loading = false;
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			playRadio(song, AllSong) {
				if (this.currentPlaying == song.liveradio_id) {
					// this.eventEmmiterService.pauseSong(true);
					// this.currentPlaying = 0;
					return true;
				} else {
					this.currentPlaying = song.liveradio_id;
					let songPlayList = [];
					console.log(song);
					songPlayList.push(song);

					if (AllSong && AllSong.length > 0) {
						const removeDuplicatesongLists = AllSong.filter((songObj) => {
							return songObj.live_radio_id != song.liveradio_id;
						});
						songPlayList = songPlayList.concat(removeDuplicatesongLists);
						const finalPlayList = JSON.parse(JSON.stringify(this.preparePlayListArry(songPlayList)));
						this.changePlaySong(finalPlayList);
					} else {
						const finalPlayList = JSON.parse(JSON.stringify(this.preparePlayListArry(songPlayList)));
						this.changePlaySong(finalPlayList);
					}
				}
			},
			changePlaySong(songDetailObj) {
				const removeDuplicatesong = songDetailObj.filter((songObj) => {
					return songObj.live_radio_id != this.currentPlaying;
				});
				this.emitCurrentSongId(removeDuplicatesong[0].id);
				this.emitSongChange(removeDuplicatesong);
			},
			emitCurrentSongId(id) {
				EventEmmiter.$emit("currentSongId", id);
			},
			emitSongChange(songObject) {
				EventEmmiter.$emit("currentSongObject", songObject);
			},
			preparePlayListArry(songListArry) {
				let songListResArry = [];
				if (songListArry && songListArry.length > 0) {
					songListArry.forEach((songObjN) => {
						const tempListArry = [];
						const newSongObj = {};
						newSongObj.id           = songObjN.live_radio_id;
						newSongObj.title        = songObjN.song_name;
						newSongObj.file         = (songObjN.song ? songObjN.song : ' ');
						newSongObj.poster       = songObjN.song_image;
						newSongObj.description  = '';
						tempListArry.push(newSongObj);
						songListResArry = songListResArry.concat([newSongObj]);
					});
				}
				return songListResArry;
			}
		},
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getLiveRadio();
		}
	}
</script>