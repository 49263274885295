<template>
	<div class="musiccategory">
		<section class="catrgory bg-grey">
			<div class="container">
				<div class="category-title">
					<h5>Trendings </h5>
					<router-link to="/mixes"><p>View All</p></router-link>
				</div>

				<div class="row">
					
					<div class="gride-container">
						<div class="gride-box" v-for="value in musiccategory" :key="value.songcategory_id">
							<router-link :to="{name: 'CategoryMusic', params:{categoryId: value.songcategory_id}}">
								<div class="loader-static">
									<div class="loader-dynamic">
										<img :src="value.image"  @error="$event.target.src='assets/images/placeholder.png'">
									</div>
									<img src="assets/images/Square.png" class="stactic-img">
								</div>
								<div class="gride-content">
									<h6>{{ value.name }}</h6>
								
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			
		</section>
	</div>
</template>

<script>
	export default {
		name: "MusicCategory",
		props:['musiccategory']
	}
</script>