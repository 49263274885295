<template>
	<div class="footer">
		<loader v-if="loading"></loader>
		
		<!-- footer section -->
		<footer class="bg-grey">
		<SponsorBanner :sponsorBanner="sponsor_banner"></SponsorBanner>
			<div class="backtotop" id="button">
				<i class="far fa-long-arrow-up"></i>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-sm-12 text-center">
						<div class="logo-footer">
							<router-link to="/" title="Home"> <img :src="logo.image"></router-link>
						</div>
					</div>
				</div>
				<div class="footer-data-wrapper">
					<div class="row">
						<div class="col-sm-4 col-md-4 text-center">
							<div class="contact-us-wrapper">

										<ul>

											<h5>Info</h5>

											<li><a :href="'mailto:'+contactus.email"><span><i class="fal fa-envelope"></i></span> 
												<p>{{ contactus.email }}</p></a>
											</li>
											<li><a :href="contactus.website" target="blank"><span><i class="fal fa-globe"></i></span> 
												<p>{{ contactus.website }}</p></a>
											</li>
											<li><a :href="contactus.phone" target="blank"><span><i class="fal fa-phone-alt"></i></span> 
												<p>{{ contactus.phone }}</p></a>
											</li>
											<li><a :href="contactus.address" target="blank"><span><i class="fas fa-map-marker-alt"></i></span> 
												<p>{{contactus.address}}</p></a>
											</li>
										</ul>

									</div>
						</div>
						<div class="col-sm-4 col-md-4 text-center">
							<h5 class="text-center">Download The App<br/><span style="font-size: 8px;">Now Available On Google Play And The Apple Store.</span></h5>
							<ul class="flex-ul justify-content-center">
								<li>
									<a :href="downloadapp.android_link" title="Play Store" target="blank"><img src="assets/images/Google-play.png"></a>
								</li>
								<li>
									<a :href="downloadapp.ios_link" target="blank" title="App Store"><img src="assets/images/App-store-Icons.png"></a>
								</li>
								<li>
									<a :href="downloadapp.durisimo_link" target="blank" title="App Store"><img src="assets/images/Durisimo-app-store.png"></a>
								</li>
							</ul>
							<h5 class="pt-4 text-center">Social Media</h5>
							<ul class="flex-ul social-media justify-content-center">
								<li>
									<a :href="socialmedia.facebook_link" target="blank" title="facebook"><span class="icon"><i class="fab fa-facebook-f"></i></span>
									</a>
								</li>
								<li>
									<a :href="socialmedia.instagram_link" target="blank" title="instagram"><span class="icon"><i class="fab fa-instagram"></i></span></a>
								</li>
								<li>
									<a :href="socialmedia.twitter_link" target="blank" title="twitter"><span class="icon"><i class="fab fa-twitter"></i></span></a>
								</li>
								<li>
									<a :href="socialmedia.youtube_link"><span class="icon"><i class="fal fa-globe"></i></span></a>
								</li>
							</ul>
						</div>
						<div class="col-sm-4 col-md-4 text-center">
								<!-- <div class="contact-form">
									<h5>Contact Us</h5>
									<form @submit.prevent="postcontactus">
										<div class="form-group">
											<input type="text" class="from-control" placeholder="Name" v-model="name" required>
										</div>
										<div class="form-group">
											<input type="email" class="from-control" placeholder="Email" v-model="email" required>
										</div>
										<div class="form-group">
											<input type="text" class="from-control" placeholder="Message" v-model="message" required>
										</div>
										<button type="submit" class="section-btn btn-lg">
											Send
										</button>
									</form>
								</div> -->
								<iframe height="700" title="Embedded Wufoo Form" allowtransparency="true"  scrolling="no" style="width:100%;border:none" sandbox="allow-popups-to-escape-sandbox allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin" :src="this.booking">Fill out my Wufoo form!</a> </iframe> 
						</div>
					</div>
				</div>
			</div>
			<div class="copy-right">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 col-md-12 text-center">
							<p>{{ copyright.text }}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<!-- footer end -->
	</div>
</template>
<script>
	import { HTTP } from '../../_helper/http-constants.js';
	import SponsorBanner from '../home/sponsorbanner.vue'; 
	export default {
		name: "Footer",
		components: {
			SponsorBanner
		},
		data(){
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				logo: '',
				downloadapp: '',
				socialmedia: '',
				copyright: '',
				loading: false,
				contactus: '',
				name: '',
				email: '',
				message: '',
				booking:'',
				sponsor_banner: null
			}
		},
		methods: {
			getfooterData(){
				this.loading = true;
				HTTP.get('/cms').then(res => {
					if(res.data.status === 200){
						this.logo = res.data.logo_data[0];
						this.downloadapp = res.data.downloadourapp_data[0];
						this.socialmedia = res.data.socialmedia_data[0];
						this.copyright = res.data.copyright_data[0];
						this.sponsor_banner = res.data.sponsorbanner_data;
						this.loading = false;
						this.contactus = res.data.contactus_data[0];
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			getBooking(){
				HTTP.get('/booking').then(res => {

					this.booking = res.data.data;

					console.log("booking data",this.booking);

					
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			postcontactus(){
				this.loading = true;
				HTTP.post('/contactrequest',{
					name: this.name,
					email: this.email,
					message: this.message
				}).then(res => {
					if(res.data.status === 200){
						this.name = "";
						this.email = "";
						this.message = "";
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'success',
							title: res.data.message
						});
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
		},
		mounted(){
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getfooterData();
			this.getBooking();
		}
	}
</script>