import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyCQILS-9c2YZ4KKIXoThtlri5vWpMjB3ws",
    authDomain: "dj-elbambino.firebaseapp.com",
    databaseURL: "https://dj-elbambino-default-rtdb.firebaseio.com",
    projectId: "dj-elbambino",
    storageBucket: "dj-elbambino.appspot.com",
    messagingSenderId: "650834365223",
    appId: "1:650834365223:web:f3b967e649b845bd75b702",
    measurementId: "G-JZ0SSQH41N"
};

firebase.initializeApp(config);

export default firebase.database();