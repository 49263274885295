<template>
  <div id="app">
    <!-- <component v-bind:is="header"/> -->
    <headernav />
    <div class="mt5 mb5 min-height">
      <router-view />
    </div>
    <Footer />
    <AudioPlayer />
  </div>
</template>

<script>
import headernav from "./components/layout/nav.vue";
import AudioPlayer from "./components/layout/audioplayer.vue";
import Footer from "./components/layout/footer.vue";

export default {
  name: "App",
  components: {
    headernav,
    Footer,
    AudioPlayer,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
