<template>
	<div class="playlistsongs">
		<loader v-if="loading"></loader>
		<section class="playlist-song pt-5 mt-5 bg-grey">
			<div class="same-space pt-4 mt-4 pb-5 ">
				<div class="container">
					<div class="main-heading pb-5">
						<div class="pattern">
							<img src="assets/images/Design-Square-Ellipse.png">
						</div>
						<h1 class="title-section pt-0 pb-2">
							{{ playlist_name }}
						</h1>
					</div>

					<div class="gride-container" v-if="playlistsongs.length > 0">
						<div class="gride-box" v-for="value in playlistsongs" :key="value.playlistsong_id">
							<div class="loader-static">
								<div class="loader-dynamic">
									<img :src="value.song_image"  @error="$event.target.src='assets/images/placeholder.png'">
								</div>
								<img src="assets/images/Square.png" class="stactic-img">
								<div class="hover-play">
									<div class="play">
										<img src="assets/images/play-icon.png">
									</div>
									<div class="list-icon-drop">
										<div class="dropdown">
											<a class="list-icon" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<img src="assets/images/menu.png">
											</a>

											<div class="dropdown-content" aria-labelledby="dropdownMenuLink">
												<a class="dropdown-item" href="javascript:void();" @click="RemovePlaylistSong(user.user_id, value.playlistsong_id)">Remove</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="gride-content">
								<h6>{{ value.song_name }}</h6>
								<p>{{ value.song_artist }}</p>
							</div>
						</div>
					</div>
					<div class="nodata" v-else>
						<h1>No Records Found</h1>
					</div>
					<br>
					<div class="number-of"  v-if="last_page > 1">
						<button class="btn btn-primary btn-sm" :disabled="current_page === 1" @click="prevCategory">Prev</button>
						{{ current_page }} / {{ last_page }}
						<button class="btn btn-primary btn-sm" :disabled="current_page === pages" @click="nextCategory">Next</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import {HTTP} from '../../_helper/http-constants.js';
	export default {
		name: "PlaylistSongs",
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				playlistID: this.$route.params.playlistID,
				playlistsongs: null,
				playlist_name : '',
				current_page: 1,
				last_page:0,
				pages: null,
				limit: 50,
				loading: false
			}
		},
		methods: {
			nextCategory() {
				this.current_page = (this.pages > this.current_page) ? parseInt(this.current_page) + 1 : this.current_page;
				this.getPlaylistSongs();
			},
			prevCategory() {
				this.current_page = (this.current_page > 1) ? parseInt(this.current_page) - 1 : this.current_page;
				this.getPlaylistSongs();
			},
			getPlaylistSongs() {
				this.loading = true;
				HTTP.post(`/playlistsong?limit=${this.limit}&page=${this.current_page}`,{
						user_id: this.user.user_id,
						playlist_id: this.playlistID
					}).then(res => {
					if(res.data.status === 200){
						this.playlistsongs = res.data.data;
						this.playlist_name = res.data.playlist_name;
						this.current_page = res.data.current_page;
						this.last_page = res.data.last_page;
						this.pages = res.data.last_page;
						this.loading = false;
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err)
				});
			},
			RemovePlaylistSong(user_id = '', playlistsong_id = '') {
				this.loading = true;
				HTTP.post('/playlistsong/remove',{
						user_id: user_id,
						playlistsong_id: playlistsong_id
					}).then(res => {
					if(res.data.status === 200){
						this.getPlaylistSongs();
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'success',
							title: res.data.message
						});
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err)
				});
			}
		},
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getPlaylistSongs();
		}
	}
</script>