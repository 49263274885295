import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import Loader from './components/loader.vue';
import VueCarousel from 'vue-carousel';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueCarousel);
Vue.component('loader', Loader);
// const Toast = VueSweetalert2.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   timer: 3000,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener('mouseenter', VueSweetalert2.stopTimer)
//     toast.addEventListener('mouseleave', VueSweetalert2.resumeTimer)
//   }
// })

// Vue.use(Toast);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
