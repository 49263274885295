<template>
	<div class="container">
		<loader v-if="loading"></loader>
		<section class="playlist top-spacing bg-grey categories_listing">
			<div class="same-space pt-4 mt-4 pb-5 ">
				<div class="container max_wid">
					<div class="main-heading pb-3">
						<div class="pattern">
							<img src="assets/images/Design-Square-Ellipse.png">
						</div>
						<h1 class="title-section pt-0 pb-2">
							Mixes
						</h1>
					</div>
					

					<div class="row">					
							<div class="gride-container">
								<div class="gride-box" v-for="value in songs_category" :key="value.songcategory_id">
									<router-link :to="{name: 'CategoryMusic', params:{categoryId: value.songcategory_id}}">
										<div class="loader-static">
											<div class="loader-dynamic">
												<img :src="value.image"  @error="$event.target.src='assets/images/placeholder.png'">
											</div>
											<img src="assets/images/Square.png" class="stactic-img">
										</div>
										<div class="gride-content">
											<h6>{{ value.name }}</h6>
										
										</div>
									</router-link>
								</div>
							</div>
					</div>
					

					<br>
					<div class="number-of" v-if="last_page > 1">
						<button class="btn btn-primary btn-sm" :disabled="current_page === 1" @click="prevCategory">Prev</button>
						{{ current_page }} / {{ last_page }}
						<button class="btn btn-primary btn-sm" :disabled="current_page === pages" @click="nextCategory">Next</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import {HTTP} from '../../_helper/http-constants.js';
	export default {
		name: "AllMusicCategory",
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				menuId: this.$route.params.menuId,
				songs_category: null,
				current_page: 1,
				last_page:0,
				pages: null,
				limit: 20,
				loading: false
			}
		},
		methods: {
			nextCategory() {
				this.current_page = (this.pages > this.current_page) ? parseInt(this.current_page) + 1 : this.current_page;
				this.getAllMusicCategory();
			},
			prevCategory() {
				this.current_page = (this.current_page > 1) ? parseInt(this.current_page) - 1 : this.current_page;
				this.getAllMusicCategory();
			},
			getAllMusicCategory() {
				
				this.loading = true;
				HTTP.get(`/mixes/?limit=${this.limit}&page=${this.current_page}`)
					.then(res => {
						if(res.data.status === 200){
							this.songs_category = res.data.data;
							this.current_page = res.data.current_page;
							this.last_page = res.data.last_page;
							this.pages = res.data.last_page;
							this.loading = false;
						}else{
							this.$swal.fire({
								toast: true,
								position: 'top-end',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
								icon: 'warning',
								title: res.data.message
							});
							this.loading = false;
						}
					}).catch(err => {
						this.loading = false;
						console.log(err)
					});
			}
		},
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getAllMusicCategory();
		}
	}
</script>