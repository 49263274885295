<template>
	<div class="sponsorbanner bg-grey">
		<section class="sponsor-banner music-section">
			<carousel :per-page="1" :mouse-drag="true" :loop="true" :autoplay="true" :autoplayTimeout="5000" :speed="500">
				
					<slide v-for="slider in sponsorBanner" :key="slider.sponsorbanner_id">
						
						<div class="container">
							<a :href="slider.url" target="blank"><img :src="slider.image" width="100%"></a>
							</div>
					</slide>
			</carousel>
		</section>
	</div>
</template>
<script>
	export default {
		name: 'SponsorBanner',
		props: ['sponsorBanner']
	}
</script>