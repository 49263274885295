<template>
	<div class="audio-player">
		<div style="width:95%; float:left" id="player"></div>
		<div class="audioplayer-playlist" v-if="hideicon == true" data-toggle="modal" data-target="#playlistSongModal">
			<i class="fal fa-list-music"></i>
		</div>
		<div class="modal fade play-list-modal Modal-key Green-modal show audio-player" id="playlistSongModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<button type="button" data-dismiss="modal" aria-label="Close" class="close">
						<span aria-hidden="true">×</span>
					</button>
			
				<div class="modal-body">
					<div class="modal-wrapper">
						<div class="modal-title">
							<h3>Recent Songs</h3>
							<div class="modal-form">
							
							<div class="playlist-box mt-4">
								<ul v-if="playList.length > 0">
									<li v-for="(value, index) in playList" :key="value.id">
										<div class="playlist-song-wrapper">
											<div class="playlist-image-wrapper">
												<span class="song-poster"><img :src="value.poster"   @error="$event.target.src='assets/images/placeholder.png'"></span>
												<span class="song-name">{{value.title}}</span>
											</div>
											<div class="playlistsong-button">
											<span class="song-play-btn"><a href="javascript:void();" class="section-btn btn-lg" @click="PlaySongFromPlaylist(value, playList, index)">Play</a></span>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
				<!-- <div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle" style="color: #000 !important;">PlayList Songs</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<ul v-if="playList.length > 0">
						<li v-for="(value, index) in playList" :key="value.id">
							<div>
								<span ><img style="height: 80px;width: 80px;border-radius: 50%;" :src="value.poster"></span>
								<span style="color: #000 !important;">{{value.title}}</span>
								<span style="color: #000 !important;"><a href="javascript:void();" class="section-btn btn-lg" @click="PlaySongFromPlaylist(value, playList, index)">Play</a></span>
							</div>
						</li>
					</ul>
				</div>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>
	// import $ from "jquery";
	import { EventEmmiter } from "../../eventEmmiter.js";
	export default {
		name: "AudioPlayer",
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				SongsObject: {},
				songRawData: [],
				playList: [],
				player: null,
				currentSongId: 0,
				hideicon: false
			}
		},
		methods: {
			PlaySongFromPlaylist(song, AllSong, Index) {
				if (Number(localStorage.getItem("currentSongId")) == song.pjs_id) {
					// this.eventEmmiterService.pauseSong(true);
					// this.currentPlaying = 0;
					return true;
				} else {
					this.emitCurrentSongId(song.pjs_id);
					window.parent.loadPlayerFunction(song, AllSong, Index);
				}
			},
			emitCurrentSongId(id) {
				EventEmmiter.$emit("currentSongId", id);
			},
		},
		created() {
		},
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			EventEmmiter.$on("currentSongId", songId=> {  
				this.currentSongId = songId;
				localStorage.setItem("currentSongId", this.currentSongId);
			});
			EventEmmiter.$on("currentSongObject", songObject=> {  
				if (songObject) {
					this.hideicon  = true;
					this.playList = [];
					songObject.forEach(data => {
						this.playList.push(data)
					});
					window.parent.loadPlayerFunction(songObject[0], songObject, 0);
				}
			}); 
		}
	}
</script>
