<template>
	<div class="loader">
		<img src="assets/images/loader.svg">
    </div>
</template>

<script>
	export default {
		name: 'Loader'
	}
</script>